import moment from 'moment-timezone';

export default class DateUtils {
    /**
     * Returns whether a store is in the coming soon phase or not
     * 
     * @param store 
     * @param allowBefore 
     * @returns 
     */
    static storeIsComingSoon(store: any, allowBefore = false) {
        if (!store) {
            return false;
        }

        let now = store?.timezone ? moment.tz(store?.timezone) : moment();

        if (!store?.operations?.storeOpening?.websiteLaunchDate) {
            if (allowBefore) {
                return true;
            }

            return false;
        }

        let websiteLaunch = store?.timezone ? moment.tz(store?.operations?.storeOpening?.websiteLaunchDate, store?.timezone) : moment(store?.operations?.storeOpening?.websiteLaunchDate);

        if (now.toDate() >= websiteLaunch?.toDate()) {
            if (!store?.operations?.storeOpening?.prelaunchStartDate) {
                return true;
            }

            let prelaunch = store?.timezone ? moment.tz(store?.operations?.storeOpening?.prelaunchStartDate, store?.timezone) : moment(store?.operations?.storeOpening?.prelaunchStartDate);
            if (now.toDate() >= prelaunch.toDate()) {
                return false;
            }

            if (store?.operations?.storeOpening?.firstBillDate) {
                let fbd = store?.timezone ? moment.tz(store?.operations?.storeOpening?.firstBillDate, store?.timezone) : moment(store?.operations?.storeOpening?.firstBillDate);

                if (now.toDate() >= fbd.toDate()) {
                    return false;
                }
            }
        }

        if (allowBefore) {
            return true;
        }

        return false;
    }

    /**
     * Returns whether the store is in prelaunch or not
     * 
     * @param store 
     * @returns 
     */
    static storeIsPrelaunch(store: any) {
        if (!store) {
            return false;
        }

        let now = store?.timezone ? moment.tz(store?.timezone) : moment();

        if (!store?.operations?.storeOpening?.prelaunchStartDate) {
            return false;
        }

        let prelaunch = store?.timezone ? moment.tz(store?.operations?.storeOpening?.prelaunchStartDate, store?.timezone) : moment(store?.operations?.storeOpening?.prelaunchStartDate);

        if (now.toDate() >= prelaunch.toDate()) {
            if (!store?.operations?.storeOpening?.firstBillDate) {
                return true;
            }

            let fbd = store?.timezone ? moment.tz(store?.operations?.storeOpening?.firstBillDate, store?.timezone) : moment(store?.operations?.storeOpening?.firstBillDate);

            if (now.toDate() >= fbd.toDate()) {
                return false;
            }

            return true;
        }

        return false;
    }

    /**
     * Returns whether the store is live or not
     * 
     * @param store 
     * @returns 
     */
    static storeIsLive(store: any) {
        if (!store) {
            return false;
        }

        let now = store?.timezone ? moment().tz(store?.timezone) : moment();

        if (!store?.operations?.storeOpening?.firstBillDate) {
            return false;
        }

        let prelaunch = store?.timezone ? moment.tz(store?.operations?.storeOpening?.firstBillDate, store?.timezone) : moment(store?.operations?.storeOpening?.firstBillDate);

        if (now.toDate() >= prelaunch.toDate()) {
            return true;
        }

        return false;
    }

    static storeIsOpenRetail(store: any) {
        if (!store) {
            return false;
        }

        let now = store?.timezone ? moment().tz(store?.timezone) : moment();

        if (!store?.operations?.storeOpening?.openingDate
        ) {
            return false;
        }

        let prelaunch = store?.timezone ? moment.tz(store?.operations?.storeOpening?.openingDate
            , store?.timezone) : moment(store?.operations?.storeOpening?.openingDate);

        if (now.toDate() >= prelaunch.toDate()) {
            return true;
        }

        return false;
    }

    static storeCanScheduleConsultations(store: any) {
        if (!store) {
            return false;
        }

        let now = store?.timezone ? moment().tz(store?.timezone) : moment();

        if (!store?.operations?.storeOpening?.openingDate
        ) {
            return false;
        }

        let prelaunch = store?.timezone ? moment.tz(store?.operations?.storeOpening?.openingDate
            , store?.timezone) : moment(store?.operations?.storeOpening?.openingDate);

        if (now.toDate() >= prelaunch.subtract(3, 'weeks').toDate()) {
            return true;
        }

        return false;
    }



    /**
     * Gets the next bill date
     * 
     * @param asMoment 
     * @returns 
     */
    static getNextBillDate(asMoment = false) {
        let now = moment();

        let next = now.clone().day(6).startOf('day');

        if (now.day() >= 6) {
            next.add(1, 'weeks');
        }

        if (asMoment) {
            return next;
        }

        return next.toDate();
    }

    /**
     * Checks if it is after the cutoff
     * 
     * @param billDate 
     * @returns 
     */
    static isAfterCutoff(billDate: any = '') {
        let now = moment();

        let cutoff = moment(billDate).day(3).hour(23).minute(59).second(59).millisecond(0);

        return now.isAfter(cutoff);
    }

    /**
     * Returns whether an upcoming order has been skipped based on the next bill date
     * 
     * @param nextBillDate 
     * @param orderDate 
     * @returns 
     */
    static orderIsSkipped(nextBillDate: any, orderDate: any) {
        let now = moment(nextBillDate).startOf('day');

        let cutoff = moment(orderDate).startOf('day');

        return now.isAfter(cutoff);
    }
}